<template>
  <v-container>
    <v-card class="mx-auto" width="700">
      <v-card-text>
        <div id="text">STEP 1. 약관에 동의해 주세요</div>
        <p class="text-h6 text--primary ">
          <br>개인정보 수집 및 이용
        </p>
        <v-data-table dense outlined class="pa-1 ml-1 mr-1 mb-3 elevation-4 v-data-table1" style="margin-top: -15px"
          id="agreeTerms" 
          ref="agreeTerms"
              :headers="agreeHeaders"
              :items="agreeItems"
              fixed-header
              hide-default-footer
            >
        </v-data-table>
        <v-checkbox class="agreeCheck"
          color=#3a4f3f
          v-model="agreeCheck"
          label="동의"
        >
        </v-checkbox>
      </v-card-text>
      <v-card-actions class="d-block" >
        <v-row justify= "center" >
          <v-spacer></v-spacer>
          <v-btn class="rounded-pill elevation-7 new-btn ma-4 mb-5" style="height: 40px; width: 100px;" 
            id="cancel" 
            ref="cancel" 
            @click="cancel()">
            취소
          </v-btn>
          <v-btn class="rounded-pill elevation-7 normal-btn ma-4 mb-5" style="height: 40px; width: 100px;" 
            id="next" 
            ref="next" 
            type="submit" 
            @click.prevent="next()"
          >
            다음
          </v-btn>
          <v-spacer ></v-spacer>
        </v-row>
      </v-card-actions>
          
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "JoinStep2",
  components: {},
  data() {
    return {
      agreeCheck: false,
      agreeItems: [
        {col1: "회원제 서비스 이용 / 본인확인", col2: "이름/아이디/비밀번호/생일/성별/이메일/휴대폰/생년월일", col3: "회원탈퇴 후 6일까지"},
        {col1: "회원제 서비스 이용 / 상품구매", col2: "연락처/휴대폰/주소/IP", col3: "회원탈퇴 후 3개월까지"},
      ],
      agreeHeaders: [
        { text: '목적', align: 'left', sortable: false, value: 'col1', width: "25%" },
        { text: '항목', sortable: false, value: 'col2', width: "45%"},
        { text: '보유기간', sortable: false, value: 'col3', width: "30%" },
      ],
    };
  },
  created() {
    this.$store.commit("resMessage","약관에 동의후 다음으로 이동하세요.");
  },
  methods: {
    cancel() {
      this.$router.push({
        name: "Login",
      });
    },
    next() {
      if (!this.agreeCheck) {
        alert("약관에 동의해 주세요.");
        return;
      }
      this.$router.push({
        name: "JoinInfo",
      });
    },
  },
};
</script>

<style lang="scss" >
.v-data-table .v-data-table-header tr th {
  font-size: 14px !important;
  font-weight: bold !important;
  color:rgb(244, 244, 244) !important;
  background-color: rgb(77, 111, 86) !important;
}
#text {
  color:#3a4f3f !important;
  font-size:12pt !important;
  font-weight: bold !important;
}
.text-color {
  color:#3a4f3f !important;
}
.agreeCheck {
  color:#3a4f3f !important;
  font-size:12pt !important;
  font-weight: bold !important;
}
</style>
<style lang="scss" scoped>
.normal-btn{
  color:white !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
</style>
